import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum, RoutingService, WhiteLabelService, UtilityService } from '../../services';
import { PurlService } from '../../services/purl.service';
import { CaseCreatedResponse } from '../../models/case/purl-response.interface';
import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { StorageKeysEnum } from '../../enums';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-purl',
    templateUrl: './purl.component.html'
})
export class PurlComponent implements OnInit {
  @ViewChild('waitDialog', { static: true }) waitDialog: TemplateRef<any>;
  isLoading = true;
  dialogRef;
  clientCode:string;
  accountCode:string;
  params = {};

  constructor(
    private dialog: MatDialog,
    public purlService: PurlService,
    public authLegacyService: AuthLegacyService,
    public routingService: RoutingService,
    public whiteLabelService: WhiteLabelService,
    public route : ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    public utilityService: UtilityService) { }

  ngOnInit(): void {
      this.parseUrlParams();
      this.authService.isAuthenticated$.subscribe(isAuthenticated => {
          if (isAuthenticated)
          {
              this.utilityService.handleLogout(`/purl?client=${this.clientCode}`);
          } else {
              this.dialogRef = this.dialog.open(this.waitDialog, {
                  width: '650px',
                  data: {},
                  disableClose: true
              });
        
              const existingCase = localStorage.getItem(StorageKeysEnum.PurlResult);
              if (existingCase){
                  this.restartInterview(JSON.parse(existingCase));
              } else {
                  this.setupUser();
              }
          }
      });
  }

  parseUrlParams(){
      const routeQueryParams = this.route.snapshot.queryParams;
      const queryParams = Object.keys(routeQueryParams).map((inputName) =>  ({ key: inputName, value: routeQueryParams[inputName]  }));
      const themeParam = queryParams.find(qp => qp.key.toLowerCase() === 'debug.theme');
      const currentTheme = this.whiteLabelService.resolveTheme(themeParam?.value);
      if (currentTheme?.code){
          this.clientCode = currentTheme.code;
      } else {
          const clientCodeParam = queryParams.find(qp => qp.key.toLowerCase() === 'client');
          if (clientCodeParam) {
              this.clientCode = clientCodeParam.value;
          }
      }

      const accountCodeParam = queryParams.find(qp => qp.key.toLowerCase() === 'account');
      if (accountCodeParam) {
          this.accountCode = accountCodeParam.value;
      }
      queryParams.forEach(param => {
          this.params[param.key] = param.value;
      });
  }

  restartInterview(existingCase: CaseCreatedResponse){
      if (this.authLegacyService.isAuthenticated()){
          this.purlService.navigateToCaseAsync(existingCase, this.route);
          this.isLoading = false;
          this.dialogRef.close();
      } else {
          this.routingService.navigateToRoute(RoutesEnum.login);
      }
  }

  async setupUser() {
      try {
          const initResult = await this.purlService.initializeCaseAsync(this.clientCode, this.accountCode, this.params);
          if (initResult) {
              const routeResult = await this.purlService.navigateToCaseAsync(initResult, this.route);
              if (!routeResult) {
                  this.routingService.navigateToRoute(RoutesEnum.login);
              }

              this.isLoading = false;
              this.dialogRef.close();
              return;
          }
      } catch(e) {
          if (this.dialogRef){
              this.dialogRef.close();
              this.routingService.navigateToRoute(RoutesEnum.login);
          }
      }
  }
}
