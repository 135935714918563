import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppService } from '../app.service';
import { AuthLegacyService } from '../auth/auth-legacy.service';
import { IConfigService } from '../config/iconfigservice';
import { FeatureToggle, StorageKeysEnum } from '../enums';
import { PurlQuestion } from '../models';
import { CaseCreatedResponse, ClientPurlVerification, CreateCaseRequest, PurlData } from '../models/case/purl-response.interface';
import { FeatureManagerService } from './global/feature-manager/feature-manager.service';
import { RoutingService } from './routing.service';

@Injectable({
    providedIn: 'root'
})
export class PurlService {
    private _apiUrl: string;
    private _isPurlUser = false;

    isPurlCaseCanceled = false;

    constructor(
    public httpClient: HttpClient,
    public configService: IConfigService,
    public authService: AuthLegacyService,
    public appService: AppService,
    public routingService: RoutingService,
    public featureService: FeatureManagerService) {
        this._apiUrl = this.configService.getConfiguration().publicApiUrl.replace('v2', 'v3');
    }


    async initializePurlUser(clientCode: string, params: {}, accountCode?: string,caseId?: string): Promise<PurlData> {

        if (!this._apiUrl) {
            this._apiUrl = this.configService.getConfiguration().publicApiUrl.replace('v2', 'v3');
        }

        let url = `${this._apiUrl}client/${clientCode}/purl`;    
        if (accountCode && accountCode !== null && accountCode !== ''){
            url = `${this._apiUrl}client/${clientCode}/accountCode/${accountCode}/purl`;
        }
        if (caseId){
            url += `/${caseId}`;
        }

        const initData = await lastValueFrom(this.httpClient.post<PurlData>(url, params));
        if (initData.isExpired) {
            return initData;
        }
        const tokenDetails = initData.tokenDetails;

        this.authService.setSessionFromAuthResult(tokenDetails);
        const isAuthV2 = this.featureService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled;
        localStorage.setItem('legacy', `${!isAuthV2}`);

        await this.authService.loadAuthenticatedDataAsync();
        this._isPurlUser = true;

        return initData;
    }

    isPurlUser(){
        return this._isPurlUser;
    }

    async initializeCaseAsync(clientCode: string, accountCode: string, params: {}) {
        try {
            if (!this._apiUrl) {
                this._apiUrl = this.configService.getConfiguration().publicApiUrl.replace('v2', 'v3');
            }
            const initData = await this.initializePurlUser(clientCode, params, accountCode);
            const account = initData.account;
            const orderSchema = initData.orderSchema;

            const isAuthV2 = this.featureService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled;
            let token = initData.tokenDetails.id_token;
            if (isAuthV2){
                token = initData.tokenDetails.access_token;
            }

            const request: CreateCaseRequest = {
                clientCode: clientCode,
                accountCode: account.code.split('-')[1],
                authToken: token,
                requestBody: {
                    ownerEmail: '',
                    accountOrderSchema: orderSchema
                },
                accountVersion: account.versionNumber
            };

            const caseCreatedResult = await this.createCaseAsync(request);
            caseCreatedResult.account = account;
            caseCreatedResult.clientCode = clientCode;
            return caseCreatedResult;
        }
        catch(e)
        {
            this.appService.showResponseErrorMsg(e);
            throw e;
        }
    }

    async createCaseAsync(request: CreateCaseRequest): Promise<CaseCreatedResponse> {
        const url = `${this._apiUrl}client/${request.clientCode}/account/${request.accountCode}/${request.accountVersion}/order/Applicant`;

        return lastValueFrom(this.httpClient.post<CaseCreatedResponse>(url,
            request.requestBody, { headers: { 'Authorization': `Bearer ${request.authToken}` } }
        ));

    }

    navigateToCaseAsync(initResult: CaseCreatedResponse, relativeTo: any) {
        localStorage.setItem(StorageKeysEnum.PurlResult, JSON.stringify(initResult));
        const url = `client/${initResult.clientCode}/account/${initResult.account.code}/${initResult.account.versionNumber}/interview/${initResult.id}`;
        return this.routingService.navigateToAsync(url, relativeTo);
    }

    verifyPurl(authToken, clientId, caseId, purlQuestions: PurlQuestion[]): Promise<ClientPurlVerification> {
        const url = `${this._apiUrl}client/${clientId}/case/${caseId}/purl/verify`;

        return lastValueFrom(this.httpClient.post<ClientPurlVerification>(url,
            purlQuestions, {
                headers: { 'Authorization': `Bearer ${authToken}`, 'X-Tenant-Guid': clientId }
            }
        ));
    }
}
